<template>
  <div class="animated fadeIn">
    <v-col cols="12">
      <v-card-title style="background-color: transparent !important">
        <v-row>
          <v-col md="6" cols="12">
            <h3>مدیریت درخواست‌های در منزل</h3>
          </v-col>
          <v-col v-if="role == 'reception'" md="6" cols="12">
            <v-btn
              class="primary-btn"
              style="float: left"
              @click="gotoNewService()"
            >
              <h6 class="mt-1">
                <v-icon left>add</v-icon>
                ثبت درخواست جدید
              </h6>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <mobile-text v-if="deviceType == 'mobile'" :title="''" />

      <v-card class="cards pa-3" v-else>
        <v-card-text>
          <div>
            <v-row class="time-row">
              <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                <span id="dateFrom">
                  <v-text-field
                    outlined
                    dense
                    type="text"
                    append-icon="calendar_today"
                    v-model="dateFrom"
                    label=" تاریخ از "
                    :editable="true"
                    class="date-input"
                  >
                  </v-text-field>
                </span>

                <date-picker
                  v-model="dateFrom"
                  element="dateFrom"
                  color="#00a7b7"
                />
              </v-col>
              <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                <span id="dateTo">
                  <v-text-field
                    outlined
                    dense
                    type="text"
                    append-icon="calendar_today"
                    v-model="dateTo"
                    label=" تاریخ تا "
                    :editable="true"
                    class="date-input"
                  >
                  </v-text-field>
                </span>

                <date-picker
                  v-model="dateTo"
                  element="dateTo"
                  color="#00a7b7"
                />
              </v-col>
            </v-row>
            <v-row class="time-row">
              <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                <span id="timeFrom">
                  <v-text-field
                    outlined
                    dense
                    append-icon="schedule"
                    v-model="timeFrom"
                    label=" ساعت از "
                    :editable="true"
                    class="date-input"
                  >
                  </v-text-field>
                </span>

                <date-picker
                  v-model="timeFrom"
                  element="timeFrom"
                  color="#00a7b7"
                  type="time"
                /> </v-col
              ><v-col cols="12" sm="12" md="6" lg="6" xl="6">
                <span id="timeTo">
                  <v-text-field
                    outlined
                    dense
                    append-icon="schedule"
                    v-model="timeTo"
                    label=" ساعت تا "
                    :editable="true"
                    class="date-input"
                  >
                  </v-text-field>
                </span>

                <date-picker
                  v-model="timeTo"
                  element="timeTo"
                  color="#00a7b7"
                  type="time"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-btn
                  class="submit-btn primary-btn"
                  style="float: right"
                  @click="requests()"
                  :disabled="Busy"
                  >اعمال
                </v-btn>
              </v-col>
            </v-row>
            <hr />
            <v-row align-v="end">
              <v-col></v-col>
              <v-col v-if="role != 'reception'">
                <v-btn
                  :disabled="Busy || !Items.length"
                  style="float: left"
                  class="secondary-btn mb-2"
                  @click="$refs.homeCaresExcel.$el.click()"
                  >دریافت اکسل لیست درخواست‌ها
                </v-btn>
                <vue-excel-xlsx
                  v-show="false"
                  ref="homeCaresExcel"
                  :data="Items"
                  :columns="
                    excelFields.map((x) => {
                      return {
                        label: x.label,
                        field: x.key,
                      };
                    })
                  "
                  :filename="
                    'لیست درخواست های در منزل از ' + dateFrom + ' تا ' + dateTo
                  "
                  :sheetname="currentDate"
                >
                  دریافت اکسل
                </vue-excel-xlsx>
              </v-col>
            </v-row>
            <v-text-field
              v-model="Filter"
              prepend-inner-icon="mdi-magnify"
              label="جستجو"
              single-line
              hide-details
              rounded
              filled
              clearable
              @filtered="onFiltered"
              class="text-right w-80 mb-2 search-input"
            ></v-text-field>
            <b-table
              responsive
              show-empty
              :fields="Fields"
              :items="Items"
              empty-text="در بازه زمانی انتخاب شده درخواستی ثبت نشده‌است"
              empty-filtered-text="در بازه زمانی انتخاب شده درخواستی ثبت نشده‌است   "
              :busy="Busy"
              :filter="Filter"
              :current-page="CurrentPage"
              :per-page="PerPage"
              @filtered="onFiltered"
            >
              <template v-slot:head()="data">
                <div style="text-align: center; vertical-align: middle">
                  {{ data.label }}
                </div>
              </template>
              <template v-slot:cell()="data">
                <div style="text-align: center; vertical-align: middle">
                  {{ data.value }}
                </div>
              </template>
              <template v-slot:cell(index)="data">
                <div style="text-align: center; vertical-align: middle">
                  {{ data.index + PerPage * (CurrentPage - 1) + 1 }}
                </div>
              </template>
              <template v-slot:cell(operation)="data">
                <div style="text-align: center; vertical-align: middle">
                  <v-btn
                    class="primary-btn pa-2"
                    :to="
                      '/' +
                      (role == 'reception' ? 'reception' : 'admin') +
                      '/EditHomeCare/' +
                      data.item.id
                    "
                    style="width: 100%; margin-top: 2px"
                    ><v-icon>visibility</v-icon>
                  </v-btn>
                  <v-btn
                    v-if="role == 'reception'"
                    class="secondary-btn mt-2 pa-2"
                    @click="popup(data.item)"
                    style="width: 100%"
                    ><v-icon>receipt_long</v-icon>
                  </v-btn>
                </div>
              </template>
              <template v-slot:cell(visitCost)="data">
                <div style="text-align: center; vertical-align: middle">
                  {{ Number(data.value).toLocaleString() }}
                </div>
              </template>
              <div slot="table-busy" class="text-center primary--text my-2">
                <v-progress-circular
                  indeterminate
                  color="primary"
                  class="align-middle"
                ></v-progress-circular>
              </div>
            </b-table>
            <v-pagination
              v-model="CurrentPage"
              :length="Math.ceil(TotalRows / PerPage)"
              :total-visible="5"
              prev-icon="arrow_back"
              next-icon="arrow_forward"
              style="float: center"
            ></v-pagination>
            <v-select
              label="تعداد در هر صفحه:"
              style="width: 150px"
              v-model="PerPage"
              :items="perPageOptions"
              item-text="text"
              item-value="value"
            ></v-select>
          </div>
        </v-card-text>
      </v-card>
    </v-col>
    <!-- receipt -->
    <v-card
      class="pa-2"
      v-show="false"
      style="float: left"
      id="Receipt"
      v-if="isMounted"
    >
      <div class="container mb-0" style="border: 2px solid black">
        <div
          class="pa-1"
          style="
            text-align: center;
            margin: auto;
            font-size: 15px;
            border-bottom: 4px solid black;
          "
        >
          <img src="/receiptLogo.png" width="100" height="100" alt="" />
          <br />
          درمانگاه شبانه روزی پارسیان البرز
        </div>
        <p style="direction: rtl; font-weight: 900">
          <br />
          {{ "نام بیمار: " + reciept.patientName }}
          <br />
          {{ "نوع خدمت: " + reciept.requestType }}
          <br />
          {{ "تاریخ درخواستی: " + reciept.date }}
          <br />
          {{ "ساعت درخواستی: " + reciept.clock }}
          <br />
          {{ "شماره همراه: " + reciept.mobile }}
          <br />
          {{ "شماره ثابت: " + reciept.phoneNum }}
          <br />
          {{ "آدرس: " + reciept.address }}
          <br />
          {{ "شیوه پرداخت: " + reciept.paymentMethod }}
        </p>

        <b-table
          bordered
          :fields="recieptFields"
          :items="reciept.services"
          small="small"
          responsive
        >
          <template v-slot:head()="data">
            <div style="text-align: center; vertical-align: middle">
              {{ data.label }}
            </div>
          </template>
          <template v-slot:cell()="data">
            <div style="text-align: center; vertical-align: middle">
              {{ data.value }}
            </div>
          </template>
          <template v-slot:cell(cost)="data">
            <div style="text-align: center; vertical-align: middle">
              {{ Number(data.item.cost).toLocaleString() }}
            </div>
          </template>
        </b-table>
        <h4 style="float: right">
          {{ "مبلغ کل:" + Number(reciept.cost).toLocaleString() + "ریال" }}
        </h4>
        <br />
      </div>
      <br />
      <!-- <barcode value="http://my.parsianalborz.com" displayValue="false" width="2" height="60">
        </barcode>   -->
      <h4 style="text-align: center">http://my.parsianalborz.com</h4>
    </v-card>
    <!-- receipt -->
  </div>
</template>
<script>
import moment from "moment-jalaali";
import VuePersianDatetimePicker from "vue-persian-datetime-picker";
import VueBarcode from "vue-barcode";
const MobileText = () => import("@/components/MobileText");
export default {
  components: {
    datePicker: VuePersianDatetimePicker,
    barcode: VueBarcode,
    MobileText
  },
  data() {
    return {
      // reciept
      reciept: {},

      recieptFields: [
        { key: "name", label: "خدمت" },
        { key: "number", label: "تعداد" },
        { key: "cost", label: "قیمت" },
      ],
      isMounted: false,
      // reciept
      CurrentPage: 1,
      PerPage: 10,
      perPageOptions: [
        { value: 10, text: "10" },
        { value: 25, text: "25" },
        { value: 50, text: "50" },
        { value: 100, text: "100" },
      ],
      TotalRows: "",
      Filter: "",
      Busy: true,
      Fields: [
        { key: "index", label: "#" },
        { key: "patientName", label: "نام بیمار" },
        { key: "demander", label: "نام درخواست کننده" },
        { key: "mobile", label: "تلفن همراه" },
        { key: "date", label: "تاریخ درخواست" },
        { key: "clock", label: "ساعت درخواستی" },
        { key: "demandType", label: "نوع خدمت" },
        { key: "agent", label: "مسئول خدمت" },
        { key: "receptionName", label: "کارمند پذیرش" },
        { key: "files", label: "گزارش" },
        { key: "status", label: "وضعیت" },
        { key: "operation", label: "مدیریت" },
      ],
      excelFields: [
        { key: "patientName", label: "نام بیمار" },
        { key: "demander", label: "نام درخواست کننده" },
        { key: "mobile", label: "تلفن همراه" },
        { key: "date", label: "تاریخ درخواست" },
        { key: "clock", label: "ساعت درخواستی" },
        { key: "demandType", label: "نوع خدمت" },
        { key: "agent", label: "مسئول خدمت" },
        { key: "files", label: "گزارش" },
        { key: "status", label: "وضعیت" },
      ],
      Items: [],
      dateFrom: moment(new Date()).subtract(30, "day").format("jYYYY/jMM/jDD"),
      dateTo: moment(new Date()).format("jYYYY/jMM/jDD"),
      timeFrom: "00:00",
      timeTo: "23:59",
      currentDate: moment(new Date()).format("jYYYY-jMM-jDD"),
      role: "",
      requestId: "",
    };
  },

  methods: {
    gotoNewService() {
      this.$router.push("/reception/NewHomeCare");
    },
    popup(item) {
      this.vLoading = true;
      this.$http
        .post(
          this.baseUrl + "/clinic/homeCare/visit/print",
          { outRequestId: item.id },
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          this.reciept = res.data;
          this.requestId = item.id;
          this.isMounted = true;
          this.vLoading = false;
        })
        .then(() => {
          this.printReceipt();
          this.isMounted = false;
        })
        .catch((err) => {
          console.log(err);
          this.toast("خطا: " + err.response.data, "error");
          this.vLoading = false;
        });
    },
    printReceipt() {
      // printing receipt information for user
      var myWindow = window.open("#", "Receipt", "height=auto,width=800");
      myWindow.document.write(
        "<html><head><link rel='stylesheet' href='/receipt.css'><title>Receipt</title>"
      );
      myWindow.document.write("</head><body>");
      myWindow.document.write(document.getElementById("Receipt").innerHTML);
      myWindow.document.write("</body></html>");
      myWindow.document.close();

      myWindow.onload = function () {
        myWindow.focus();
        setTimeout(() => {
          myWindow.print();
          myWindow.close();
        }, 500);
      };
    },
    requests() {
      this.Busy = true;
      this.$http
        .post(
          this.baseUrl + "/clinic/homeCare/visits/getAll",
          {
            startDate: this.dateFrom + this.timeFrom,
            endDate: this.dateTo + this.timeTo,
          },
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          this.Busy = false;
          if (res.status == 200) {
            this.Items = res.data;
            this.Items.forEach((x) => {
              x.files = x.files ? "دارد" : "ندارد";
              if (x.status == "لغو") {
                x._rowVariant = "danger";
              }
            });
            this.TotalRows = this.Items.length;
            this.CurrentPage = 1;
          } else {
            this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
          }
        })
        .catch((err) => {
          this.toast("خطا: " + err.response.data, "error");
          this.Busy = false;
        });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.TotalRows = filteredItems.length;
      this.CurrentPage = 1;
    },
  },
  mounted() {
    this.role = localStorage.getItem("role");
    this.requests();
  },
};
</script>
